






























































@import '@design';

.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: rgba(black, 0.6);
    padding-top: 30px;
    padding-bottom: 30px;
}

// TITLES
.texts {
    text-align: center;
    color: var(--color);
    padding-left: 15px;
    padding-right: 15px;
}
.title {
    @include font-heading;

    font-size: 2em;
    opacity: 0;
    transform: translateX(-100%);
    color: var(--color-secondary);
}
.details {
    margin-top: 3px;
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0;
    transform: translateX(100%);
}

// Start Number
.message {
    margin-top: 30px;
    font-size: 16px;
    text-align: center;
    opacity: 0;
    transform: translateY(-100%);
    animation: date 500ms ease forwards;
    animation-delay: 2.5s;
    color: var(--color-secondary);
    width: 70%;
    max-width: 300px;
}
.messageButton {
    margin-top: 20px;
}

.title,
.details {
    animation: names 1s ease forwards;
    animation-delay: 1.5s;
}

@keyframes names {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes date {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
